import React from 'react';
import ReactDOM from "react-dom/client";
import App from './App';
import './styles/index.css';
import { HelmetProvider } from 'react-helmet-async';
// import './assets/favicon.ico';
// import './assets/robots.txt';
// import './assets/sitemap.xml';
// import './assets/zkip-logo.png';

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <HelmetProvider>
      <App />
    </HelmetProvider>
  </React.StrictMode>,
);
