// SocialMediaIcons.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faTwitter, faGithub, faYoutube } from '@fortawesome/free-brands-svg-icons';

const SocialMediaIcons = () => {
  const socialMediaLinks = [
    { icon: faLinkedin, url: 'https://www.linkedin.com/company/zkiplabs' },
    { icon: faTwitter, url: 'https://twitter.com/ZkipLabs' },
    { icon: faGithub, url: 'https://github.com/zkiplabs' },
    { icon: faYoutube, url: 'https://www.youtube.com/@ZkipLabs' },
  ];

  return (
    <div className="social-media-icons">
      {socialMediaLinks.map((socialMedia, index) => (
        <a key={index} href={socialMedia.url} target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={socialMedia.icon} size="2x" />
        </a>
      ))}
    </div>
  );
};

export default SocialMediaIcons;
