import React from 'react';
import { Link } from 'react-router-dom';
// import AIAnimation from '../components/AIAnimation';
import Services from '../components/Services';
import Foreword from '../components/Foreword';
import Logo from '../components/Logo';
import { Helmet } from 'react-helmet-async';
import { Seo } from '../components/Seo';
import SocialMediaIcons from '../components/SocialMediaIcons';


const Home: React.FC = () => {
  return (
    <div className="container py-5">
      <Seo
        title="ZkipLabs OpenStack, Security, and AI Solutions | ZkipLabs"
        description="ZkipLabs offers OpenStack cloud solutions, advanced cybersecurity services, and deep learning model deployment. Explore our comprehensive services designed to meet your business needs."
        path=""
        imageUrl="https://zkiplabs.com/zkip-logo-lav-bg.png"
        imageWidth="254"
        imageHeight="254"
      />
      <div className="header" style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
        <Logo />
        {/* <h1 className="text-center mb-4 light-text">Welcome to ZkipLabs</h1> */}
      </div>
      {/* <div className='home-anim' style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
        <AIAnimation />
      </div> */}
      <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
        <Foreword />
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
        <Services />
      </div>
      <div className="row">
        <div className="col-md-6 offset-md-3">
          <div className="card-bottom" style={{ display: 'flex', justifyContent: 'center' }}>
            <Link to="/contact-us" className="btn btn-primary" >
              Contact us
            </Link>
          </div>
        </div>
      </div>
      <footer className="footer">
        <SocialMediaIcons />
      </footer>
    </div>
  );
};

export default Home;
