import React from 'react';

const Foreword: React.FC = () => {
  const services = [
    { title: 'Comprehensive OpenStack Solutions', description: 'From deployment to optimization, we deliver tailored OpenStack solutions to fuel your cloud initiatives, ensuring scalability, flexibility, and performance.' },
    { title: 'Deep Learning Model Deployment', description: 'Accelerate your AI journey with our expertise in deep learning model deployment. We provide scalable environments, GPU acceleration, and seamless integration to deploy and manage your AI models effectively.' },
    { title: 'Advanced Cybersecurity', description: 'Protect your digital assets with our cutting-edge cybersecurity services. We employ proactive threat detection, robust encryption, and compliance adherence to safeguard your data and infrastructure.' },
    { title: 'Customized Solutions', description: `Recognizing the unique requirements of your OpenStack infrastructure and deep learning initiatives, we offer tailored solutions. Our expert team conducts in-depth assessments of your environment and objectives, crafting strategies that optimize your OpenStack deployment and streamline your deep learning model deployment process.` },
    { title: 'Ethical Conduct and Trust', description: `We conduct our operations with the highest ethical standards, ensuring complete transparency and trust in our client relationships. Integrity is paramount in our interactions, and we prioritize the confidentiality and privacy of our clients' information.` },
  ];
  return (
    <div className="services-section bg-dark">
      <div className="container py-5">
        <h2 className="text-center text-white mb-4">OpenStack</h2>
        <div className="foreword">
          <p className="text-center text-white mb-4">
            ZkipLabs offers OpenStack cloud solutions, advanced cybersecurity, and deep learning model deployment. Explore our comprehensive services designed to meet your business needs.
            <br /><br />
          </p>
        </div>
        <div className="row">
          {services.map((service, index) => (
            <div key={index} className="col-md-4 mb-4">
              <div className="card h-100 bg-dark text-white">
                <div className="card-body">
                  <h5 className="card-title">{service.title}</h5>
                  <p className="card-text">{service.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Foreword;
