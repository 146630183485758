import React from 'react';

const Services: React.FC = () => {
  const services = [
    { title: 'OpenStack Deployment', description: 'We offer complete OpenStack deployment services, ensuring a scalable and flexible cloud infrastructure tailored to your business needs.' },
    { title: 'Deep Learning Model Deployment', description: 'Deploy and manage your deep learning models efficiently, leveraging our expertise in scalable AI environments and GPU acceleration.' },
    { title: 'Cloud Security Solutions', description: 'Our security solutions protect your cloud environment with advanced threat detection, risk assessment, and strategic defense mechanisms.' },
  ];

  return (
    <div className="services-section bg-dark">
      <div className="container py-5">
        <h2 className="text-center text-white mb-4">OpenStack</h2>
        <div className="row">
          {services.map((service, index) => (
            <div key={index} className="col-md-4 mb-4">
              <div className="card h-100 bg-dark text-white">
                <div className="card-body">
                  <h5 className="card-title">{service.title}</h5>
                  <p className="card-text">{service.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Services;
