// components/ContactUsForm.tsx

import React, { useRef, useState } from 'react';

const ContactUsForm: React.FC<{ handleSubmit: (e: React.FormEvent<HTMLFormElement>, formData: any, setFormData: any, formRef: any) => void, }> = ({ handleSubmit }) => {
  const [formData, setFormData] = useState({ name: '', email: '', message: '', loading: false });
  const formRef = useRef(null);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const submitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleSubmit(event, formData, setFormData, formRef);
  };

  return (
    <form ref={formRef} onSubmit={submitForm}>
          <div className="mb-3">
            <label htmlFor="name" className="form-label">
              Name
            </label>
            <input
              type="text"
              className="form-control form-control-sm col-8"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="email" className="form-label">
              Email
            </label>
            <input
              type="email"
              className="form-control form-control-sm col-8"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="message" className="form-label">
              Message
            </label>
            <textarea
              className="form-control form-control-sm col-8"
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
            ></textarea>
          </div>
          <button type="submit" className="btn btn-primary" disabled={formData.loading}>
            {formData.loading ? "Loading..." : "Submit" }
          </button>
        </form>
  );
};

export default ContactUsForm;
