import React, { useState } from 'react';
import ContactUsForm from '../components/ContactUsForm';
import { Helmet } from 'react-helmet-async';
import { Seo } from '../components/Seo';
import SocialMediaIcons from '../components/SocialMediaIcons';


const ContactUs: React.FC = () => {

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>, formData: any, setFormData: any, formRef: any) => {
    event.preventDefault();
    if (!formData.email || !formData.message) {
      alert("email address not entered");
      return;
    }
    // const emailSubject = 'Consultation Inquiry';
    // const emailRecipient = 'admin@zkiplabs.com';
  
    // const emailBody = `Name: ${formData.name}\nEmail: ${formData.email}\nMessage: ${formData.message}`;
  
    // const mailToLink = `mailto:${emailRecipient}?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(emailBody)}`;
  
    // window.location.href = mailToLink;
    setFormData({ name: formData.name, email: formData.email, message: formData.message, loading: true });
    const googleScriptUrl = 'https://script.google.com/macros/s/AKfycbxS6Q4GMK-XQtfhZwgyKjNNiQ7VwWGXXb-QXizZx6TCQ0QrQXWkyl4agWlHEtvyDBPMlw/exec';
    fetch(googleScriptUrl, {
      method: 'POST',
      body: new FormData(formRef.current),
    }).then(res => {
      console.log("SUCCESSFULLY SUBMITTED");
      console.log(res);
      setFormData({ name: '', email: '', message: '', loading: false });
    }).catch(err => {
      console.log(err);
      setFormData({ name: formData.name, email: formData.email, message: formData.message, loading: false });
    });
  };

  return (
    <div className="container py-5">
      <Seo
        title="ZkipLabs Support: Contact Us | ZkipLabs"
        description="Got a question or need technical support? Our team is here to help. Contact us!"
        path="/contact-us"
        imageUrl="https://zkiplabs.com/zkip-logo-lav-bg.png"
        imageWidth="254"
        imageHeight="254"
      />
      <h1>Contact Us</h1>
      <div className="contact-info">
        <p className="light-text">
          <strong>Organization:</strong> ZkipLabs
        </p>
        <p className="light-text">
          <strong>Address:</strong> Bellandur, Bengaluru, India
        </p>
        <p className="light-text">
          <strong>Email:</strong> admin@zkiplabs.com
        </p>
      </div>
      <h2>Send us a message</h2>
      <div className="form-wrapper">
        <ContactUsForm handleSubmit={handleSubmit} />
      </div>
      <footer className="footer">
        <SocialMediaIcons />
      </footer>
    </div>
  );
};

export default ContactUs;
