import { Helmet } from 'react-helmet-async';

 export const Seo = (
    { title, description, path, imageUrl, imageWidth, imageHeight }: 
    {
        title: string, 
        description: string, 
        path: string, 
        imageUrl: string,
        imageWidth: string,
        imageHeight: string
    }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta name="description" content={description} />
      <meta name="image" content={imageUrl} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imageUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={"https://zkiplabs.com" + path} />
      <meta property="og:image:width" content={imageWidth} />
      <meta property="og:image:height" content={imageHeight} />
      <meta property="og:site_name" content="ZkipLabs" />
      <meta name="author" content="ZkipLabs" />
      <meta name="twitter:creator" content="ZkipLabs" />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={imageUrl} />
      <meta name="twitter:site" content="@ZkipLabs" />
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      <meta name="language" content="English" />
      <meta name="keywords" content="ZkipLabs OpenStack, Security, and AI Solutions | ZkipLabs" />
    </Helmet>
  );
};
