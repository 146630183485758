// CareersPage.js

import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { Seo } from "../components/Seo";
import SocialMediaIcons from "../components/SocialMediaIcons";


const CareersPage = () => {
  return (
    <div className="careers-page">
      <Seo
        title="ZkipLabs Careers: Join the Team | ZkipLabs"
        description="Explore ZkipLabs career opportunities. Solve impactful problems, while fostering personal and professional growth."
        path="/careers"
        imageUrl="https://zkiplabs.com/zkip-logo-lav-bg.png"
        imageWidth="254"
        imageHeight="254"
      />
      <header className="careerpage-header">
        <h1>Join Our Team</h1>
        <p>We're looking for talented individuals to join us on our journey.</p>
      </header>

      <section className="careerpage-job-listings">
        <div className="careerpage-job-card">
          <h2>Software Intern</h2>
          <p>
            We are seeking a skilled software engineering intern to contribute to the development
            of cutting-edge applications.
          </p>
          <Link to="https://forms.gle/H5KtkXpNNHfWcHCa9" target="_blank">
              <button className="btn btn-primary">Apply Now</button>
          </Link>
        </div>
      </section>

      <footer className="footer">
        <p>Contact us at careers@zkiplabs.com for inquiries or more information.</p>
        <SocialMediaIcons />
      </footer>
    </div>
  );
};

export default CareersPage;